import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {SvgIcon} from "@mui/material";
import {Global} from "@emotion/react";
import Sidebar from "./Sidebar";
import {ReactComponent as SiteLogo} from '../logo.svg';
import MessageBox from "./MessageBox";
import {useHighlight} from "../hook/useHighlight";

const Layout = ({children}) => {
  const {highlight} = useHighlight();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Global styles={highlight}/>
      <MessageBox/>
      <AppBar position="fixed" sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#8a2b33'
      }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <SvgIcon component={SiteLogo} inheritViewBox sx={{marginRight: 2}} />
            ELTE Lágymányosi Campus Déli Tömb útvonaltervező
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar/>
      {children}
    </Box>
  );
}

export default Layout;