import React from 'react';
import 'ol/ol.css';
import SvgLayer from "./SvgLayer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {useMap} from "../../hook/useMap";

const Map = () => {
  const {
    map,
    mapRef,
    floors,
    currentFloor,
    setCurrentFloor,
  } = useMap();

  return (
    <Box component='main' sx={{flexGrow: 1, p: 1}}>
      <Toolbar />
        <Box className='currentFloor' sx={{px: 3, m: 2}}>
          <h3>{floors[currentFloor]}</h3>
        </Box>
        <div ref={mapRef} className='map'>
          <SvgLayer map={map} currentFloor={currentFloor} setCurrentFloor={setCurrentFloor} />
        </div>
    </Box>
  );
};

export default Map;