import React from 'react';
import {Alert, Snackbar} from "@mui/material";
import {useMessage} from "../hook/useMessage";

const MessageBox = () => {
  const {
    messages,
    open,
    handleClose,
  } = useMessage();

  return (
    <Snackbar
      open={open && messages.length > 0}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      autoHideDuration={8000}
      onClose={handleClose}
    >
      <Alert severity={messages[0]?.severity} sx={{width: '100%'}}>
        {messages[0]?.text}
      </Alert>
    </Snackbar>
  );
};

export default MessageBox;