import {createSlice} from "@reduxjs/toolkit";

const name = 'message';
const initialState = [];

export const messageSlice = createSlice({
  name,
  initialState,
  reducers: {
    addMessage: (state, {payload: {text, severity}}) => {
      state.push({text, severity});
    },
    removeMessage: (state) => {
      state.shift();
    },
  }
});

export const selectMessage = (state) => state.message;

export const {addMessage, removeMessage} = messageSlice.actions;
export const messageReducer = messageSlice.reducer;