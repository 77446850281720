import React from 'react';
import {useLayer} from "../../hook/useLayer";

const SvgLayer = ({map, currentFloor, setCurrentFloor}) => {
  const {
    layerRef,
    backgrounds,
  } = useLayer({map, currentFloor, setCurrentFloor});

  return (
    <div ref={layerRef}
         className='svgLayer'
         dangerouslySetInnerHTML={backgrounds[currentFloor]}
    />
  );
};

export default SvgLayer;