import React from 'react';
import {Elevator, LockPerson, MeetingRoom, Stairs} from "@mui/icons-material";

const PathItemAttributes = ({attr, length}) => {
  return (
    <>
      {attr > 0 ? <span className='pathIcons'>
        {attr & 8 ? <MeetingRoom sx={{fontSize: 25}}/> : ''}
        {attr & 4 ? <Elevator sx={{fontSize: 25}}/> : ''}
        {attr & 2 ? <Stairs sx={{fontSize: 25}}/> : ''}
        {attr & 1 ? <LockPerson sx={{fontSize: 25}}/> : ''}
      </span> : ''}
      <span className='pathLength'>{length ? `${Math.round(1600 * length / 145) / 100}m` : ''}</span>
    </>
  );
};

export default PathItemAttributes;