import {useQuery} from "react-query";
import {useDispatch} from "react-redux";
import {addMessage} from "../redux/slices/messageSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

const severity = 'error';
const apiErrors = [
    {
        text: 'Hálózati hiba, ellenőrizze az internetkapcsolatát!',
        severity,
    },
    {
        text: 'A keresett elem nem található!',
        severity    },
    {
        text: 'A kiszolgáló nem tudja értelmezni a kérést!',
        severity,
    },
    {
        text: 'Kiszolgáló oldali hiba!',
        severity,
    },
];

class HttpError extends Error {
    constructor(response) {
        super(`HTTP ${response.status}`);
        this.name = "HttpError";
        this.status = response.status;
        this.response = response;
    }
}

const errorHandler = async (error, dispatch) => {
    if (!error?.status) {
        dispatch(addMessage(apiErrors[0]));
    } else if (error?.status === 404) {
        dispatch(addMessage(apiErrors[1]));
    } else if (error?.status === 400) {
        dispatch(addMessage(apiErrors[2]));
    } else if (error?.status === 500) {
        dispatch(addMessage(apiErrors[3]));
    }
}

const handleResponse = resp => {
    if (resp.status !== 200) {
        throw new HttpError(resp);
    }

    return resp.json();
};

const query = location => fetch(`${baseUrl}${location}`, {
    method: 'GET',
    headers: {'Accept': 'application/json'}
}).then(resp => handleResponse(resp));

export const useGet = location => {
    const dispatch = useDispatch();
    const {isLoading, data} = useQuery(
      location,
      () => query(location),
      {
          retry: false,
          refetchOnWindowFocus: false,
          onError: error => errorHandler(error, dispatch),
      }
    );

    return {isLoading, data};
}

export const useGetNoAuto = location => {
    const dispatch = useDispatch();
    const {isLoading, data, refetch} = useQuery(
      location,
      () => query(location),
      {
          enabled: false,
          retry: false,
          refetchOnWindowFocus: false,
          onError: error => errorHandler(error, dispatch),
      }
    );

    return {isLoading, data, refetch};
}