import {useEffect, useRef} from "react";
import {useBg} from "./useBg";
import {useDispatch, useSelector} from "react-redux";
import {selectRoom, setPath} from "../redux/slices/roomSlice";
import Layer from "ol/layer/Layer";
import {composeCssTransform} from "ol/transform";
import {useGetNoAuto} from "./useApi";
import {Circle, LineString} from "ol/geom";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import VectorLayer from "ol/layer/Vector";
import {Fill, Stroke, Style} from "ol/style";
import {setLoading} from "../redux/slices/loadingSlice";

const width = 1150;
const height = 1080;
const scaleFactor = 0.113043;

const rmLayer = (map, layerName) => {
  [...map.getLayers().getArray()]
    .filter(layer => layer.get('name') === layerName)
    .forEach(layer => map.removeLayer(layer));
};

const adjustGeometry = (geometry) => {
  geometry.translate(-width/2, -height/2);
  geometry.scale(scaleFactor, -scaleFactor, [0, 0]);
};

const addCoordinates = (waypoint, array) => {
  const coordinates = waypoint
    .coordinates
    .split(",")
    .map(c => parseFloat(c));
  array.push(coordinates);
};

/*
TODO:
 - room highlight
*/

export const useLayer = ({map, currentFloor, setCurrentFloor}) => {
  const layerRef = useRef();
  const {backgrounds} = useBg();
  const dispatch = useDispatch();
  const roomStore = useSelector(selectRoom);
  const {isLoading, data, refetch} =
    useGetNoAuto(`/v1/path/${roomStore.room1?.label}/${roomStore.room2?.label}/${roomStore.searchFlags}`);

  useEffect(() => {
    dispatch(setLoading({loading: isLoading}));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (roomStore.current?.layer) {
      setCurrentFloor(roomStore.current.layer);
    }
    if (roomStore.room1 && roomStore.room2) {
      refetch();
    }
  }, [refetch, roomStore, setCurrentFloor]);

  useEffect(() => {
    if (!map) return;
    const svgResolution = 130 / width;

    map.addLayer(new Layer({
      name: 'svg',
      render: (frameState) => {
        const scale = svgResolution / frameState.viewState.resolution;
        const center = frameState.viewState.center;
        const size = frameState.size;
        layerRef.current.style.transform = composeCssTransform(
          size[0] / 2,
          size[1] / 2,
          scale,
          scale,
          frameState.viewState.rotation,
          -center[0] / svgResolution - width / 2,
          center[1] / svgResolution - height / 2
        );
        return layerRef.current;
      }
    }));

    return () => {
      if (map) rmLayer(map, 'svg');
    }
  }, [map, backgrounds]);

  useEffect(() => {
    if (data) {
      dispatch(setPath({path: data}));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data && data.length > 0) {
      const currentRoom =  data[roomStore.pathIndex];

      if (currentRoom?.coordinates) {
        const vSource = new VectorSource({});
        const currentCoordinates = currentRoom
          .coordinates.split(",")
          .map(c => parseFloat(c));
        const current = new Circle(currentCoordinates, 8);
        const coordinates = [];

        adjustGeometry(current);
        vSource.addFeature(new Feature({geometry: current}));

        for (
          let index = roomStore.pathIndex, waypointOnFloor = data[index];
          index >= 0 && waypointOnFloor.Room?.layer === currentFloor;
          --index, waypointOnFloor = data[index]
        )
        {
          addCoordinates(waypointOnFloor, coordinates);
        }

        coordinates.reverse();

        if (roomStore.pathIndex < data.length - 1) {
          for (
            let index = roomStore.pathIndex + 1, waypointOnFloor = data[index];
            index < data.length && waypointOnFloor.Room?.layer === currentFloor;
            ++index, waypointOnFloor = data[index]
          )
          {
            addCoordinates(waypointOnFloor, coordinates);
          }
        }

        if (coordinates.length > 1) {
          const lineString = new LineString(coordinates);
          adjustGeometry(lineString);
          vSource.addFeature(new Feature({geometry: lineString}));
        }

        map.addLayer(new VectorLayer({
          name: 'vector',
          source: vSource,
          style: new Style({
            stroke: new Stroke({ color: '#cc3236', width: 5 }),
            fill: new Fill({ color: '#cc3236', weight: 10 }),
          })
        }));
      }
    }

    return () => {
      if (map) rmLayer(map, 'vector');
    }
  }, [data, currentFloor, map, roomStore]);

  return {
    layerRef,
    backgrounds,
    currentFloor,
  }
}