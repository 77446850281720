import React from 'react';
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  LinearProgress,
  MenuList,
  MenuItem,
  ListItemText,
  TextField,
  Checkbox,
  Grid,
  Tooltip,
} from "@mui/material";
import {useSidebar} from "../hook/useSidebar";
import {
  Elevator,
  LockPerson,
  MeetingRoom,
  Stairs
} from "@mui/icons-material";
import PathItemAttributes from "./PathItemAttributes";

const drawerWidth = 350;

const Sidebar = () => {
  const {
    roomState,
    roomStore,
    setRoomState,
    loading,
    data,
    flags,
    handleInput,
    collapsedPath,
    filter,
    getOptionLabel,
    renderOption,
    handlePathClick,
    renderPathItem,
    handleCheck,
  } = useSidebar();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
      }}
    >
      <Toolbar/>
      <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={100}/>
      <Box sx={{px: 2, pt: 5}}>
        {/* known issue with options filled by async search: https://github.com/mui/material-ui/issues/29727
            ignore warning: MUI: The value provided to Autocomplete is invalid.  */}
        <Autocomplete
          disablePortal
          id="room-lookup"
          noOptionsText="Nem létező terem"
          value={roomState.room1}
          onChange={(_, val) => {
            setRoomState({
              ...roomState,
              room1: val,
            });
          }}
          options={data?.rooms ? data.rooms : []}
          getOptionLabel={getOptionLabel}
          sx={{width: 300, mb: 5}}
          onInput={(event) => handleInput(event.target)}
          filterOptions={filter}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          renderInput={(params) => <TextField {...params} label="Teremkeresés"/>}
          renderOption={renderOption}
        />
        <Autocomplete
          disablePortal
          id="room-goto"
          noOptionsText="Nem létező terem"
          value={roomState.room2}
          onChange={(_, val) => {
            setRoomState({
              ...roomState,
              room2: val,
            });
          }}
          options={data?.rooms ? data.rooms : []}
          getOptionLabel={getOptionLabel}
          sx={{width: 300, mb: 3}}
          onInput={(event) => handleInput(event.target)}
          filterOptions={filter}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          renderInput={(params) => <TextField {...params} label="Célkeresés"/>}
          renderOption={renderOption}
        />
        <Grid container>
          <Grid item xs={3}><Elevator sx={{fontSize: 40}}/></Grid>
          <Grid item xs={3}><Stairs sx={{fontSize: 40}}/></Grid>
          <Grid item xs={3}><LockPerson sx={{fontSize: 40}}/></Grid>
          <Grid item xs={3}><MeetingRoom sx={{fontSize: 40}}/></Grid>
          <Grid item xs={3}>
            <Tooltip title='Lift engedélyezése'>
            <Checkbox
              onChange={() => handleCheck(2)}
              checked={(flags & 0b100) === 0}
            />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title='Lépcső engedélyezése'>
            <Checkbox
              onChange={() => handleCheck(1)}
              checked={(flags & 0b10) === 0}
            />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title='Kulcsot igénylő útvonalak engedélyezése'>
            <Checkbox
              onChange={() => handleCheck(0)}
              checked={(flags & 1) === 0}
            />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title='Termeken történő átjárás engedélyezése'>
            <Checkbox
              onChange={() => handleCheck(3)}
              checked={flags >> 3 === 0}
            />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {collapsedPath.length > 0
        ? <h3 className='pathHeader'>Útvonal</h3>
        : roomState.room1 && roomState.room2 && !loading ?
          <h3 className='pathHeader'>Nem található útvonal!</h3> : ''}
      <Box sx={{overflow: 'auto', px: 2}}>
        <MenuList sx={{
            p: 1,
            '&& .Mui-selected': {
              backgroundColor: '#863939',
              color: '#fff'
            }
          }}
        >
          {collapsedPath.map(({wp, index, attr, length}) =>
            <MenuItem
              key={`wp-${index}`}
              onClick={() => handlePathClick({room: wp.Room, pathIndex: index})}
              selected={roomStore.pathIndex === index}
            >
              <ListItemText
                primary={renderPathItem(wp)}
                secondary={<PathItemAttributes key={`pi-${index}`} attr={attr} length={length}/>}
              />
            </MenuItem>
          )}
        </MenuList>
      </Box>
    </Drawer>
  );
};

export default Sidebar;