import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGet} from "./useApi";
import {debounce} from "@mui/material";
import {selectRoom, setCurrent, setPath, setPathIndex, setRoom, setSearchFlag} from "../redux/slices/roomSlice";
import {selectLoading} from "../redux/slices/loadingSlice";

export const useSidebar = () => {
  const [roomState, setRoomState] = useState({room1: null, room2: null});
  const [flags, setFlags] = useState(0);
  const [queryString, setQueryString] = useState('');
  const dispatch = useDispatch();
  const roomStore = useSelector(selectRoom);
  const loadingStore = useSelector(selectLoading);
  const {isLoading, data} = useGet(`/v1/entities/room/search/${queryString}`);
  const handleInput = useMemo(() => debounce(target => {
    setQueryString(target.value);
  }, 150), []);
  const filter = (opt, state) => opt.filter(o => {
    const labelFilter = o.label.toLowerCase().startsWith(state.inputValue.toLowerCase());
    return o.name ? labelFilter || o.name.toLowerCase().startsWith(state.inputValue.toLowerCase()) : labelFilter;
  });
  const getOptionLabel = opt => opt.name ? `${opt.label} (${opt.name})` : opt.label;
  const renderOption = (props, opt) => {
    const content = opt.name ? `${opt.label} (${opt.name})` : opt.label;
    return <li {...props}>{content}</li>
  };
  const loading = isLoading || loadingStore.loading;
  const collapsedPath = (() => {
    const path = roomStore.path;
    const result = []

    path.forEach((wp, i) => {
      if (i === 0 || wp.Room?.label !== path[i - 1].Room?.label) {
        result.push({wp, index: i, length: wp.GraphEdge?.length, attr: parseInt(wp.GraphEdge?.attribute)});
      } else if (wp.GraphEdge?.length){
        const last = result.slice(-1)[0];
        last.length += wp.GraphEdge.length;
        last.attr |= parseInt(wp.GraphEdge.attribute);
      }
    });

    return result;
  })();
  const handlePathClick = ({room, pathIndex}) => {
    dispatch(setCurrent({current: room}));
    dispatch(setPathIndex({pathIndex}));
  };
  const handleCheck = (index) => {
    const nextState = flags ^ (1 << index);
    setFlags(nextState);
    dispatch(setSearchFlag({searchFlags: nextState}));
  };
  const renderPathItem = useCallback((wp) => {
    if (wp.Room?.name) {
      const name = wp.Room.name
      return name.length > 18
          ? `${wp.Room?.label} (${wp.Room.name.substring(0, 15)}...)`
          : `${wp.Room?.label} (${wp.Room.name})`;
    }
    return wp.Room?.label;
  }, []);

  useEffect(() => {
    const {room1, room2} = roomState;

    dispatch(setPathIndex({pathIndex: 0}));
    dispatch(setRoom(roomState));
    dispatch(setCurrent({current: roomState.room1}));

    if (!room1 || !room2) {
      dispatch(setPath({path: []}));
    }
  }, [dispatch, roomState, flags]);

  return {
    roomState,
    roomStore,
    setRoomState,
    loading,
    data,
    flags,
    handleInput,
    collapsedPath,
    filter,
    getOptionLabel,
    renderOption,
    handlePathClick,
    renderPathItem,
    handleCheck,
  }
}