import {createSlice} from "@reduxjs/toolkit";

const name = 'loading';
const initialState = {loading: false};

export const loadingSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, {payload: {loading}}) => {
      state.loading = loading;
    }
  }
});

export const selectLoading = (state) => state.loading;

export const {setLoading} = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;