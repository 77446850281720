import {useEffect, useRef, useState} from "react";
import OLMap from "ol/Map";
import {View} from "ol";

const floors = {
  '00': '-1. emelet',
  '0' : 'földszint',
  '1' : '1. emelet',
  '2' : '2. emelet',
  '3' : '3. emelet',
  '4' : '4. emelet',
  '5' : '5. emelet',
  '6' : '6. emelet',
  '7' : '7. emelet',
  'T' : 'tetőtér',
}

export const useMap = () => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [currentFloor, setCurrentFloor] = useState('0');

  useEffect(() => {
    const olMap = new OLMap({
      target: 'map',
      view: new View({
        center: [0, 0],
        projection: 'EPSG:4326',
        extent: [-180, -90, 180, 90],
        zoom: 2,
      }),
    });
    olMap.setTarget(mapRef.current);
    setMap(olMap);

    return () => {
      if (!olMap) return;
      olMap.setTarget(null);
      setMap(null);
    }
  }, []);

  return {
    map,
    mapRef,
    floors,
    currentFloor,
    setCurrentFloor,
  }
}