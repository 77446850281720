import './App.css';
import store from "./redux/store";
import Layout from "./view/Layout";
import {Provider} from "react-redux";
import {QueryClientProvider, QueryClient} from "react-query";
import Map from "./view/map/Map";

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Map/>
        </Layout>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
