import {createSlice} from "@reduxjs/toolkit";

const name = 'room';
const initialState = {
  room1: null,
  room2: null,
  current: null,
  path: [],
  pathIndex: 0,
  searchFlags: 0,
};

export const roomSlice = createSlice({
  name,
  initialState,
  reducers: {
    setRoom: (state, {payload: {room1, room2}}) => {
      state.room1 = room1;
      state.room2 = room2;
    },
    setCurrent: (state, {payload: {current}}) => {
      state.current = current;
    },
    setPath: (state, {payload: {path}}) => {
      state.path = path;
    },
    setPathIndex: (state, {payload: {pathIndex}}) => {
      state.pathIndex = pathIndex;
    },
    setSearchFlag: (state, {payload: {searchFlags}}) => {
      state.searchFlags = searchFlags;
    }
  }
});

export const selectRoom = (state) => state.room;

export const {
  setRoom,
  setCurrent,
  setPath,
  setPathIndex,
  setSearchFlag,
} = roomSlice.actions;
export const roomReducer = roomSlice.reducer;