import {useSelector} from "react-redux";
import {selectRoom} from "../redux/slices/roomSlice";

const genSelector = (label) => label.startsWith('T') ? `#${label}` : `#_${label}`;

export const useHighlight = () => {
    const roomStore = useSelector(selectRoom);
    const highlight = (() => {
        const style = {}

        if (roomStore.room1?.label) {
            style[genSelector(roomStore.room1.label)] = {
                fill: 'rgba(0, 166, 255, 0.5)'
            }
        }

        if (roomStore.room2?.label) {
            style[genSelector(roomStore.room2.label)] = {
                fill: 'rgba(0, 255, 42, 0.5)'
            }
        }

        return style;
    })();

    return {
        highlight
    }
}