import ld00 from '../resources/LD-00.svg';
import ld0 from '../resources/LD-0.svg';
import ld1 from '../resources/LD-1.svg';
import ld2 from '../resources/LD-2.svg';
import ld3 from '../resources/LD-3.svg';
import ld4 from '../resources/LD-4.svg';
import ld5 from '../resources/LD-5.svg';
import ld6 from '../resources/LD-6.svg';
import ld7 from '../resources/LD-7.svg';
import ldt from '../resources/LD-T.svg';
import {useEffect, useState} from "react";

const bgUris = {
  '00': ld00,
  '0' : ld0,
  '1' : ld1,
  '2' : ld2,
  '3' : ld3,
  '4' : ld4,
  '5' : ld5,
  '6' : ld6,
  '7' : ld7,
  'T' : ldt,
};

export const useBg = () => {
  const [backgrounds, setBackgrounds] = useState({});

  useEffect(() => {
    const bgEntries = Object.entries(bgUris);
    const xhr = new XMLHttpRequest();
    const bg = {};

    const loadBg = (i, length) => {
      if (i >= length) {
        setBackgrounds(bg);
        return;
      }

      xhr.open('GET', bgEntries[i][1]);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          bg[bgEntries[i][0]] = {__html: xhr.responseText};
          loadBg(i + 1, length);
        }
      };
      xhr.send();
    };

    loadBg(0, bgEntries.length);
  }, []);

  return {
    backgrounds
  };
}