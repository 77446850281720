import {configureStore} from '@reduxjs/toolkit'
import {createLogger} from 'redux-logger';
import {messageReducer} from "./slices/messageSlice";
import {roomReducer} from "./slices/roomSlice";
import {loadingReducer} from "./slices/loadingSlice";

const reducer = {
  message: messageReducer,
  room: roomReducer,
  loading: loadingReducer,
};

const logger = createLogger({
  collapsed: true,
});

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const mw = getDefaultMiddleware();
    if (process.env.NODE_ENV !== 'production') {
      return mw.concat(logger)
    }
    return mw;
  },
  devTools: process.env.NODE_ENV !== 'production',
});