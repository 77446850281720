import {useDispatch, useSelector} from "react-redux";
import {removeMessage, selectMessage} from "../redux/slices/messageSlice";
import {useState} from "react";

export const useMessage = () => {
    const messages = useSelector(selectMessage);
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => dispatch(removeMessage()), 400);
        setTimeout(() => setOpen(true), 600);
    };

    return {
        messages,
        open,
        handleClose,
    }
};